#books {
	margin-bottom: 10rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.book-wrapper {
		z-index: 1;
		margin: 1rem auto 10rem auto;
		display: flex;
		flex-direction: row;
		min-height: fit-content;
		height: 100%;
		position: relative;
		padding: 0 1rem 1rem 1rem;
		padding-left: 0;

		&::before {
			content: "";
			position: absolute;
			right: 0;
			top: 50px;
			width: 98%;
			height: 100%;
			z-index: -1;
			background: $dark-gray;
		}
		.book-cover {
			flex: 1;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;

			img {
				width: 100%;
				height: auto;
				border-radius: 5px;
				box-shadow: 6px 6px 13px rgba(0, 0, 0, 0.24);
			}
		}
		.book-text {
			flex: 2;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 4.5rem 1rem 0 2rem;

			.book-title {
				margin-top: -0.5rem;
				margin-left: -0.12rem;
				font-size: 46px;
				margin-bottom: 0.6rem;
				font-weight: 500;
				color: white;
			}

			p {
				margin-bottom: 2.3rem;
				font-size: 18px;
				letter-spacing: 0.5px;
				font-weight: 300;
				color: white;
			}
		}

		@media (max-width: 769px) {
			margin: 2rem auto 10rem auto;
			flex-direction: column;
			padding: 0;

			&::before {
				top: 150px;
				width: 100%;
				height: 89%;
			}
			.book-cover {
				justify-content: center;
				align-items: center;

				img {
					width: 45%;
				}
			}
			.book-text {
				align-items: center;
				padding: 1.2rem;

				h2.book-title {
					margin: 0;
					width: 100%;
					font-size: 32px;
					margin-bottom: 0.6rem;
				}

				p {
					font-size: 16px;
					letter-spacing: 0.8px;
					line-height: 140%;
				}
			}
		}
	}
}
