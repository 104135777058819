.swiper-container {
	width: 100%;

	.swiper-button-prev {
		background-image: url("../../assets/leftArrow.svg") !important;
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center;
		left: 15px;
		&:hover {
			opacity: 0.6;
		}
		&::after {
			display: none;
		}
	}

	.swiper-button-next {
		background-image: url("../../assets/rightArrow.svg") !important;
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center;
		right: 15px;
		&:hover {
			opacity: 0.6;
		}
		&::after {
			display: none;
		}
	}

	.swiper-pagination {
		.swiper-pagination-bullet {
			background: black;
			opacity: 0.9;
		}
		.swiper-pagination-bullet-active {
			background: $orange;
		}
	}

	.swiper-slide {
		min-height: 50vh;
		height: fit-content;

		.img-bg {
			background-position: top !important;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			background-clip: border-box !important;
			height: 100%;
			width: 60%;
			margin: 3rem auto;
			.carousel-content {
				position: relative;
				z-index: 10;
				display: grid;
				grid-template-columns: 1fr 2fr;
				height: 100%;
				column-gap: 1rem;
				img {
					width: 95%;
					height: auto;
					border-radius: 5px;
					box-shadow: 2px 2px 9px rgba(56, 56, 56, 0.534);
				}

				.book-content {
					background: rgba(0, 0, 0, 0.658);
					color: white;
					height: fit-content;
					justify-self: flex-start;
					align-self: center;
					padding: 1.2rem 2rem 2rem 2rem;
					max-width: 600px;
					margin-left: 1rem;
					h2 {
						font-size: 25px;
						font-weight: bold;
						margin-bottom: 1rem;
					}
					p {
						font-size: 18px;
						font-weight: lighter;
						line-height: 140%;
						margin-bottom: 2rem;
					}
					a {
						color: white;
						font-size: 18px;
						background: $orange;
						text-decoration: none;
						padding: 0.5rem 1rem;
						&:hover {
							opacity: 0.9;
						}
					}
				}
				@media (max-width: 768px) {
					grid-template-columns: unset;
					display: flex;
					flex-direction: column-reverse;

					img {
						width: 82%;
						align-self: center;
					}
					.book-content {
						margin: 0 0 2rem 0;
						h2 {
							font-size: 25px;
							font-weight: bold;
							margin-bottom: 1rem;
						}
						p {
							font-size: 18px;
							font-weight: normal;
							margin-bottom: 2rem;
						}
						a {
							color: white;
							font-size: 18px;
							background: $orange;
							text-decoration: none;
							padding: 0.5rem 1rem;
							&:hover {
								opacity: 0.9;
							}
						}
					}
				}
			}
			@media (max-width: 560px) {
				width: 80%;
			}
		}
		.img-bg::before {
			content: "";
			-webkit-filter: blur(1px) brightness(0.8);
			-moz-filter: blur(1px) brightness(0.5);
			-ms-filter: blur(1px) brightness(0.5);
			-o-filter: blur(1px) brightness(0.5);
			filter: blur(3px) brightness(0.8);
			position: absolute;
			left: -8px;
			top: -8px;
			right: -8px;
			bottom: -8px;
			z-index: 0;
			background: inherit;
		}
	}

	// @media (max-width: 1000px) {
	// 	height: 80vh;
	// }
}

.banner {
	background: linear-gradient(20deg, black 15%, rgba(255, 123, 1, 0.87) 50%, black 85%);
	background-attachment: fixed;
	padding: 1.1rem 0;
	h2 {
		color: white;
		font-size: 40px;
		font-family: "Lato", sans-serif;
		font-style: italic;
		font-weight: 400;
		text-align: center;
	}

	@media screen and (max-width: 768px) {
		padding: 1.1rem;
		background: linear-gradient(to right, black 15%, rgba(224, 112, 6, 0.904) 50%, black 85%);
		background-attachment: unset;
		h2 {
			font-size: 23px;
		}
	}
}

.short-intro {
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	column-gap: 2rem;
	margin: 5rem auto;
	&_content {
		h2 {
			color: $orange;
			font-size: 30px;
			font-weight: bold;
			margin-bottom: 1rem;
			display: flex;
			justify-content: flex-start;
			align-items: baseline;
			flex-wrap: wrap;
			span {
				font-size: 15px;
				font-weight: normal;
				color: black;
				margin-left: 0.4rem;
			}
		}
		.quote {
			font-family: "Lato", sans-serif;
			font-size: 22px;
			margin-bottom: 1rem;
		}
		p {
			font-size: 18px;
			line-height: 130%;
			margin-bottom: 1.5rem;
			width: 85%;
		}
	}
	&_img {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		img.profile-pic {
			border-radius: 50%;
			width: 90%;
			height: auto;
			margin-bottom: 1.2rem;
		}
	}

	@media (max-width: 768px) {
		grid-template-columns: unset;
		display: flex;
		flex-direction: column-reverse;
		margin: 3rem auto;
		&_content {
			h2 {
				font-size: 23px;
				margin-bottom: 0.6rem;
			}
			.quote {
				font-size: 23px;
				margin-bottom: 0.6rem;
			}
			p {
				font-size: 16px;
				width: 100%;
			}
		}

		&_img {
			margin-bottom: 2rem;
			img.profile-pic {
				border-radius: 0;
				width: 45%;
				margin-bottom: 1rem;
			}
		}
	}
}
