#footer {
	background: #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 1.8rem;
	text-align: center;

	.scroll-to-top {
		width: 100%;
		background: #27292b;
		outline: none;
		border: none;
		color: white;
		font-size: 15px;
		padding: 0.7rem 0;
		cursor: pointer;
		font-family: "Quicksand", "sans-serif";
		&:hover {
			background: #34373a;
		}
	}

	.footer-content {
		padding: 1.8rem 0;
		h3 {
			color: white;
			font-size: 28px;
		}
		ul {
			list-style-type: none;
			display: flex;
			margin-top: 1rem;
			li a {
				color: white;
				text-decoration: none;
				margin: 0 0.8rem;
				font-weight: 300;
				font-size: 18px;
				&:hover {
					color: $orange;
				}
			}
		}
		@media (max-width: 768px) {
			h3 {
				font-size: 25px;
			}
			ul {
				margin-top: 1rem;
				li a {
					margin: 0 0.5rem;

					font-size: 16px;
				}
			}
		}
	}
}
