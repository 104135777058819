@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300;1,400&display=swap");

$orange: #ff7b01;
$dark-gray: #27292b;
$quicksand: "Quicksand", sans-serif;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: $quicksand;
	scroll-behavior: smooth;
}

.container {
	width: 85%;
	margin: 0 auto;
	max-width: 1500px;

	@media (max-width: 768px) {
		width: 92%;
	}

	@media (max-width: 450px) {
		width: 95.5%;
	}
}

.primaryButton {
	color: white;
	font-size: 22px;
	background: $orange;
	text-decoration: none;
	padding: 0.6rem 1.2rem;
	&:hover {
		opacity: 0.9;
	}
}

h2.page-title {
	margin: 3rem 0 1rem 0;
	color: $orange;
	font-size: 38px;
	font-weight: bold;
	@media (max-width: 768px) {
		font-size: 25px;
	}
}

@import "./components/_navbar.scss";
@import "./components/_footer.scss";
@import "./components/_socialMediaRow";
@import "./components/loadingIcon";
@import "./components//loadingIconTwo";
@import "./components//preloader";

@import "./pages/_home.scss";
@import "./pages/about";
@import "./pages/updates";
@import "./pages/books";
@import "./pages/contact";
