#updates {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	margin-bottom: 5rem;
	h2.page-title {
		align-self: flex-start;
	}

	iframe {
		border-radius: 5px;
		box-shadow: 2px 2px 8px rgba(56, 56, 56, 0.712);
	}
}
