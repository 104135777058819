.social-media-row {
	a {
		margin: 0 0.3rem;
		img {
			width: 28px;
			height: 28px;

			@media (max-width: 768px) {
				width: 20px;
				height: 20px;
			}
		}

		&:hover {
			opacity: 0.7;
		}
	}
}
