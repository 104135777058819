#about {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	.about_wrapper {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		margin-bottom: 4rem;

		.about_content {
			p {
				margin: 1rem 0;
				font-size: 18px;
			}
		}
		.about_img {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			img.avatar {
				width: 80%;
				height: auto;
				margin-bottom: 1rem;
			}
		}

		@media (max-width: 768px) {
			grid-template-columns: 1fr 0.5fr;
		}

		@media (max-width: 650px) {
			grid-template-columns: unset;
			display: flex;
			flex-direction: column-reverse;
			img.avatar {
				width: 60% !important;
			}
			.about_content p {
				font-size: 16px;
			}
		}
	}
}
