nav {
	background: $dark-gray;

	.nav-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 85%;
		margin: 0 auto;
		padding: 1rem 0;
		position: relative;

		.logo a {
			font-size: 35px;
			font-weight: 500;
		}

		.menu-button {
			display: none;
			background: none;
			border: none;
			outline: none;
			cursor: pointer;
			svg {
				width: 30px !important;
				height: 30px;
				color: white;
			}
		}

		.nav-links {
			ul {
				display: flex;
				list-style-type: none;
				li {
					margin: 0.4rem 0.5rem;
				}
			}
		}

		.nav-links-m {
			position: absolute;
			display: flex;
			flex-direction: column;
			top: 110%;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			background: #202020ec !important;
			border: 2px $orange solid;
			width: 105%;
			border-radius: 5px;
			z-index: 999;

			ul {
				list-style-type: none;
				li {
					padding: 1rem;
					width: 100%;
				}
			}
		}

		a {
			color: white;
			text-decoration: none;
			transition: all 0.2s;
			font-weight: 300;
			font-size: 20px;
			&:hover {
				color: #ff7b01;
			}
		}

		@media (max-width: 768px) {
			justify-content: space-between;
			.logo a {
				font-size: 25px;
			}
			.menu-button {
				display: block;
			}
			.nav-links {
				display: none;
			}
		}
	}
}
