#contact {
	margin-bottom: 4rem;

	.contact-body {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
		margin-top: 1rem;
		column-gap: 2rem;
		row-gap: 1.5rem;

		@media (max-width: 430px) {
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		}
		form {
			display: flex;
			flex-direction: column;
			font-family: $quicksand;
			max-width: 500px;
			::placeholder {
				color: rgb(185, 185, 185);
				opacity: 1; /* Firefox */
				font-family: $quicksand;
			}
			:-ms-input-placeholder {
				color: rgb(185, 185, 185);
			}
			::-ms-input-placeholder {
				color: rgb(185, 185, 185);
			}
			label {
				margin: 0;
				margin-top: 0.5rem;
				p {
					margin: 0.2rem 0;
					font-size: 1.2rem;
				}
			}
			input {
				width: 100%;
				border-radius: 5px;
				border: #9e9e9e solid 2px;
				padding: 0.7rem 0.5rem;
			}
			textarea {
				width: 100%;
				border-radius: 5px;
				border: #9e9e9e solid 2px;
				padding: 0.5rem 0.5rem;
				margin: 0;
			}
			small {
				display: none;
			}
			small.error {
				display: block;
				color: red;
				margin-top: 0.15rem;
				margin-left: 0.3rem;
			}
			button {
				background: $orange;
				border-radius: 5px;
				border: 3px solid $orange;
				color: white;
				padding: 0.8rem 1rem;
				width: fit-content;
				font-weight: 600;
				cursor: pointer;
				margin-bottom: 1rem;
				margin-top: 0.9rem;
				font-size: 1.1rem;
				font-weight: 500;
			}
			button:hover {
				opacity: 0.8;
			}
			button:disabled {
				background: #da6900;
				border: 3px solid #da6900;
				color: rgb(219, 219, 219);
			}
			button:disabled:hover {
				opacity: 1;
				cursor: not-allowed;
			}

			@media (max-width: 768px) {
				button {
					font-size: 16px;
					padding: 0.5rem 0.7rem;
				}
			}
		}

		img {
			width: 100%;
			height: auto;
		}
		.Toastify__toast-container {
			width: fit-content;
			.submit-feedback {
				background: $orange;
				color: white;
				font-size: 20px;
				font-family: $quicksand;
				width: fit-content;
				padding: 8px 0.8rem 8px 1rem;
				.Toastify__toast-body {
					margin-right: 5px;
				}
			}
			.Toastify__progress-bar {
				background: white;
			}
		}
	}
}
